/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import cookie from "react-cookies";
import { apiUrl, adminlimit } from "../../Helpers/Config";
import { GET_LISTDATA } from "../../../actions";
import { showPriceValue } from "../../Helpers/SettingHelper";
import Header from "../Layout/Header";
import Topmenu from "../Layout/Topmenu";
import Footer from "../Layout/Footer";
import Pagenation from "../Layout/Pagenation";
var module = "clientpanel/clients/";
class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editID: cookie.load("companyID"),
      totalRecords: 0,
      totalPage: 0,
      currentPage: 1,
      clientList: [],
      balanceCommisson: 0,
      company_id: "",
      loading: true,
    };
  }
  componentDidMount() {
    this.loadList(1);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.clientyList !== this.state.clientyList) {
      this.setState({
        clientList: nextProps.clientyList,
        loading: false,
        totalRecords: nextProps.totalRecords,
        totalPage: nextProps.totalPages,
        balanceCommisson: nextProps.balanceCommisson,
      });
    }
  }

  loadList(page) {
    var paramsD = "limit=" + adminlimit + "&offset=" + page;
    if (this.state.editID !== "") {
      paramsD += "&company_id=" + this.state.editID;
    }
    var params = {
      params: paramsD,
      url: apiUrl + module + "credit_list",
      authType: "client",
    };
    this.props.getListData(params);
  }

  sateValChange = (field, value) => {
    if (field === "page") {
      this.setState(
        {
          loading: true,
          currentPage: value,
        },
        function () {
          this.loadList(value);
        }
      );
    }
  };

  render() {
    return (
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header {...this.props} currentPage={"commission"} />
          <div className="layout-page">
            <Topmenu />

            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row mb-3">
                  <div className="col-lg-9 col-md-6">
                    <h4 className="fw-bold">EpicPay Commission</h4>
                    <b>Balance Commission :</b>
                    {showPriceValue(this.state.balanceCommisson)}
                  </div>
                </div>

                <div className="card">
                  <div className="table-responsive text-nowrap p-1 mt-4">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Customer</th>
                          <th>Credit Type</th>
                          <th>Credit Amount</th>
                          <th>Remarks</th>
                          <th>Credit Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.loading === true ? (
                          <tr>
                            <td colSpan={7} align="center">
                              <div
                                className="spinner-border spinner-border-lg text-primary"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            </td>
                          </tr>
                        ) : this.state.clientList.length > 0 ? (
                          this.state.clientList.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.customerName}</td>
                                <td>{item.commission_type}</td>
                                <td>
                                  {showPriceValue(
                                    item.commission_credit_amount
                                  )}
                                </td>
                                <td>{item.commission_remarks}</td>

                                <td>{item.commission_created_on}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={6} align="center">
                              No Record Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <Pagenation
                    params={{
                      totalRecords: this.state.totalRecords,
                      totalPage: this.state.totalPage,
                      currentPage: this.state.currentPage,
                    }}
                    sateValChange={this.sateValChange}
                  />
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>

        <div className="drag-target"></div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var clientList = Array();
  var clientListStatus = "";
  var totalPages = 0;
  var totalRecords = 0;
  var balanceCommisson = 0;
  if (Object.keys(state.listdata).length > 0) {
    clientListStatus = state.listdata[0].status;
    if (state.listdata[0].status === "ok") {
      clientList = state.listdata[0].result;
      totalPages = state.listdata[0].totalPages;
      totalRecords = state.listdata[0].totalRecords;
      balanceCommisson = state.listdata[0].balanceCommisson;
    }
  }
  return {
    clientyList: clientList,
    totalPages: totalPages,
    totalRecords: totalRecords,
    balanceCommisson: balanceCommisson,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListData: (datas) => {
      dispatch({ type: GET_LISTDATA, datas });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(List);
