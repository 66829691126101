/* eslint-disable */
import React, { useRef, useEffect, useState, useCallback } from "react";
import EmailEditor from "react-email-editor";
import { useDropzone } from "react-dropzone";
import { apiUrl } from "../../Helpers/Config";
import sample from "./sample.json";
import axios from "axios";
import styled from "styled-components";
var qs = require("qs");
import "./style.css";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`;

const Bar = styled.div`
  flex: 1;
  background-color: #5a5fe0;
  color: #fff;
  padding: 10px;
  display: flex;
  max-height: 70px;
  h1 {
    flex: 1;
    font-size: 16px;
    text-align: left;
    color: #fff;
    margin-top: 12px;
  }

  button {
    flex: 1;
    padding: 10px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
    background-color: #000;
    color: #fff;
    border: 0px;
    max-width: 150px;
    cursor: pointer;
  }
`;

const FileInput = styled.input`
  margin: 10px 0;
  padding: 10px;
  border: 2px solid #4caf50;
  border-radius: 5px;
`;

const UploadButton = styled.button`
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }
`;

const ProgressContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const ProgressBar = styled.div`
  height: 20px;
  background-color: #4caf50;
  border-radius: 5px;
  transition: width 0.3s;
`;

const Message = styled.p`
  margin-top: 1px;
`;
const StyledWrapper = styled.div`
  background-color: #fff;
  div {
    height: ${parseInt(window.innerHeight) - 65}px !important;
  }
`;
const MyComponent = () => {
  const emailEditorRef = useRef(null);

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      console.log("exportHtml",design, html);
      var postObject = {
        templateDesign: JSON.stringify(design),
        templateHtml: html,
        status: 'A',
      };
     /*  showLoader("do_login", "class");
   */
      axios
        .post(apiUrl + "clientpanel/clients/login", qs.stringify(postObject))
        .then((res) => {
         /*  hideLoader("do_login", "class"); */
          if (res.data.status === "ok") {
          
          } else {
           
          }
        });


    });
  };
  const [preview, setPreview] = useState(false);
  const togglePreview = () => {
    const unlayer = emailEditorRef.current?.editor;

    if (preview) {
      unlayer?.hidePreview();
      setPreview(false);
    } else {
      unlayer?.showPreview("desktop");
      setPreview(true);
    }
  };

  const onDesignLoad = (data) => {
    console.log("onDesignLoad", data);
  };

  const loadOldDesign = () => {
    if (emailEditorRef.current.editor !== null) {
      emailEditorRef.current.editor.loadDesign(sample);
      emailEditorRef.current.editor.addEventListener(
        "design:loaded",
        onDesignLoad
      );
      emailEditorRef.current.editor.registerCallback(
        "selectImage",
        function (data, done) {
          $("#mediaLibrary").modal("show");
          $("#mediaLibrary .gallery-item img").bind("click", function (e) {
            done({ url: $(e.target).attr("src") });
            $("#mediaLibrary").modal("hide");
          });
        }
      );
    } else {
      setTimeout(() => {
        loadOldDesign();
      }, 1000);
    }
  };

  useEffect(() => {
    loadOldDesign();
    loadGallery();
  }, []);

  const [galleryList, setgalleryList] = useState([]);
  const [imageURL, setimageURL] = useState("");
  const loadGallery = async () => {
    const responses = await axios.get(
      "https://walletapi.goepicpay.com/api/settings/getgallery"
    );
    if (responses?.data?.status === "ok") {
      setgalleryList(responses?.data?.result || []);
      setimageURL(responses?.data?.imageURL);
    }
  };
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [message, setMessage] = useState({ text: "", success: false });
  const onUpload = async () => {
    if (!file) {
      setMessage({ text: "No file selected", success: false });
      return;
    }
    const formData = new FormData();
    formData.append("imageFile", file);
    try {
      setMessage({ text: "", success: false });
      const response = await axios.post(
        "https://walletapi.goepicpay.com/api/settings/uploadGallery",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            console.log(
              progressEvent.loaded,
              Math.round((progressEvent.loaded * 100) / progressEvent.total),
              "progressEvent.loaded"
            );
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadPercentage(percentCompleted);
          },
        }
      );
      setMessage({ text: "File uploaded successfully", success: true });
      loadGallery();
      setTimeout(() => {
        setMessage({ text: "", success: false });
        setUploadPercentage(0);
      }, 3000);
    } catch (error) {
      console.log(error, "errorerror");
      setMessage({ text: "Error uploading file", success: false });
      setTimeout(() => {
        setUploadPercentage(0);
        setMessage({ text: "", success: false });
      }, 3000);
    }
  };
  const [file, setFiles] = useState(null);
  const [dragdropMag, setDragdropMag] = useState(
    "Drag & drop some files here, or click to select files"
  );
  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles[0]);
    setDragdropMag(acceptedFiles[0].name);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
const closeModal = () => {
  $("#mediaLibrary").modal("hide");
}
  return (
    <Container>
      <Bar>
        <h1>EpicPay Email Composer</h1>
        <button onClick={togglePreview}>
          {preview ? "Hide" : "Show"} Preview
        </button>
        <button onClick={exportHtml}>Save Design</button>
      </Bar>
      <StyledWrapper>
        <EmailEditor ref={emailEditorRef} />
      </StyledWrapper>
      <div id="mediaLibrary" className="modal" role="dialog">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">My Media Library</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div
                {...getRootProps()}
                className={`dropzone ${isDragActive ? "active" : ""}`}
              >
                <input {...getInputProps()} />
                <p>{dragdropMag}</p>
              </div>

              <UploadButton onClick={onUpload}>Upload</UploadButton>
              <ProgressContainer>
                <ProgressBar style={{ width: `${uploadPercentage}%` }} />
              </ProgressContainer>
              {console.log(message, "messagemessage")}
              {message.text !== "" && <Message className={`message ${message.success?'success':'error'}`}>{message.text}</Message>}

              <div className="gallery">
                {galleryList.length > 0 &&
                  galleryList.map((item, index) => {
                    return (
                      <div className="gallery-item" key={index}>
                        <img src={`${imageURL}${item.galleryImage}`} />
                        <div className="gallery-caption">
                          {item.galleryName}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default MyComponent;
